import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import moment from 'moment';
import UIkit from "uikit";

import { executeNetworkRequestWithAuth } from '../common/NetworkBusiness.js';
import ModalInsertRichiesta from "../components/modalInsertRichiesta.js";
import ModalViewRichiesta from "../components/modalViewRichiesta.js";
import PDFDocument from "../components/pdfDocument.js";
import 'react-big-calendar/lib/css/react-big-calendar.css';
import "react-datepicker/dist/react-datepicker.css";

const localizer = momentLocalizer(moment);
require('moment/locale/it.js');

export default () => {
    const [events, setEvents] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loaderInsert, setLoaderInsert] = useState(false);
    const [openModalInsert, setOpenModalInsert] = useState(false);

    const [logged, setLogged] = useState(true);
    const [richieste, setRichieste] = useState([]);
    const [richiestaShow, setRichiestaShow] = useState(null);
    const [durataVisita, setDurataVisita] = useState(30);

    useEffect(() => {
        if (richieste?.length > 0) {
            let richieste_fatte = richieste.map((richiesta) => richiesta.tipologia_richiesta != 1 ? ({
                id: richiesta.id,
                title: richiesta.nome ? `${richiesta.nome} ${richiesta.cognome}` : richiesta.note.split(' ').slice(0, 2).join(' '),
                start: moment(new Date(richiesta.data_prenotazione)).toDate(),
                end: richiesta.durata_visita ?
                    moment(new Date(richiesta.data_prenotazione).setMinutes(new Date(richiesta.data_prenotazione).getMinutes() + richiesta.durata_visita)).toDate() :
                    moment(new Date(richiesta.data_prenotazione).setMinutes(new Date(richiesta.data_prenotazione).getMinutes() + richiesta.durata_richiesta)).toDate()
            }) : undefined)
            setEvents([...richieste_fatte]);
        }
    }, [richieste])

    const getRichieste = async () => {
        let result = await executeNetworkRequestWithAuth("get", "getRichieste", {});
        if (result.data?.length > 0) {
            let richieste = result.data;
            richieste.sort((a, b) => (new Date(a.data_prenotazione) > new Date(b.data_prenotazione)) ? 1 : ((b.data_prenotazione > a.data_prenotazione) ? -1 : 0));
            setRichieste(richieste);
        }
        else {
            localStorage.removeItem("token");
            setLogged(false);
        }
        setLoading(false);
    }

    const inserRichiesta = async (richiestaAdd) => {
        setLoaderInsert(true);
        let note = `${richiestaAdd.cognome} ${richiestaAdd.nome} \n${richiestaAdd.note}`;
        let time = new Date(richiestaAdd.ora_prenotazione);
        let data_prenotazione = new Date(richiestaAdd.data_prenotazione);
        data_prenotazione.setHours(time.getHours());
        data_prenotazione.setMinutes(time.getMinutes());
        const richiesta = { ...richiestaAdd, data_prenotazione: data_prenotazione, note: note };
        let result = await executeNetworkRequestWithAuth("post", "insertRichiesta", richiesta);
        if (result.data) {
            UIkit.notification("<span data-uk-icon='icon: check'></span> Richiesta inserita correttamente!", { pos: 'top-right', status: 'success' });

        }
        else {
            alert(result);
        }
        setLoaderInsert(false);
        setOpenModalInsert(false);
        getRichieste();
    }

    useEffect(() => {
        const email = localStorage.getItem("email");
        const medicosDurata = [
            {
                email: "referente.direzione@pages.srl",
                durata: 20
            },
            {
                email: "ambulatorioviarecanati@gmail.com",
                durata: 20
            },
            {
                email: "bernacchi_angiologia@pontedera.it",
                durata: 15
            },
            {
                email: "filidei_angiologia@pontedera.it",
                durata: 15
            },
            {
                email: "bendinelli_cardiologia@pontedera.iT",
                durata: 15
            },
            {
                email: "pasqualetti_chirurgia@pontedera.it",
                durata: 15
            },
            {
                email: "fralleone_chirurgia@pontedera.it",
                durata: 15
            },
            {
                email: "rossetti_oculistica@pontedera.it",
                durata: 15
            },
            {
                email: "battistini_ortopedia@pontedera.it",
                durata: 15
            },
            {
                email: "casini_ecografia@pontedera.it",
                durata: 15
            },
            {
                email: "casini_ecografia@pontedera.it",
                durata: 15
            },
            {
                email: "fisioterapia_pontedera@pontedera.it",
                durata: 15
            },
            {
                email: "otorino_seccia@pontedera.it",
                durata: 15
            },
            {
                email: "otorino_segnini@pontedera.it",
                durata: 15
            },
            {
                email: "bruni_podologo@pontedera.it",
                durata: 15
            },
            {
                email: "lafauci_riab@pontedera.it",
                durata: 15
            },
            {
                email: "fustaino_senologia@pontedera.it",
                durata: 15
            },
            {
                email: "adamo_chirurgia@pontedera.it",
                durata: 15
            },
            {
                email: "fimiani_dermatologia@pontedera.it",
                durata: 15
            },
            {
                email: "mangia_dermatologia@pontedera.it",
                durata: 15
            },
            {
                email: "digangi_ginecologia@pontedera.it",
                durata: 15
            },
            {
                email: "marconi_nch@pontedera.it",
                durata: 15
            },
            {
                email: "grillai_otorino@pontedera.it",
                durata: 15
            },
            {
                email: "marconcini_oculistica@pontedera.it",
                durata: 15
            },
            {
                email: "tinagli_psichiatria@pontedera.it",
                durata: 15
            },
            {
                email: "aragona_urologia@pontedera.it",
                durata: 15
            },
            {
                email: "poggetti_ortopedia@pontedera.it",
                durata: 15
            },
            {
                email: "bresci_gastro@pontedera.it",
                durata: 15
            },
            {
                email: "giampietro_diab@pontedera.it",
                durata: 15
            },
            {
                email: "cei_chirurgia@pontedera.it",
                durata: 15
            },
            {
                email: "bertani_cei_endo@pontedera.it",
                durata: 15
            },
            {
                email: "infermeria@pontedera.it",
                durata: 15
            },
            {
                email: "sisti_endo@pontedera.it",
                durata: 15
            },
            {
                email: "difranco_logopedista@pontedera.it",
                durata: 15
            },
            {
                email: "montagnani_nutrizionista@pontedera.it",
                durata: 15
            }
        ]
        const medico = medicosDurata.filter(x => x.email === email);
        if (medico.length > 0) {
            setDurataVisita(medico[0].durata);
        }

        getRichieste();
    }, [])

    const setFocusEvent = (e) => {
        //console.log(e); 
        return;
    }

    const messages = {
        previous: '<',
        today: 'Oggi',
        next: '>',
        month: 'Mese',
        week: 'Settimana',
        day: 'Giorno',
        date: 'Data',
        time: 'Ora',
        event: 'Evento',
        showMore: (target) => <span className="ml-2" role="presentation"> {target} richieste aggiuntive</span>
    }

    const eventStyleGetter = (e) => {
        return {
            style: {
                backgroundColor: '#2196F3',
                color: 'white',
                fontSize: '14px',
                border: '0px',
                display: 'block',
                marginTop: "5px",
            }
        };
    }

    const infoFocus = (e) => {
        const richiesta = richieste.filter(x => x.id === e.id);
        if (richiesta.length > 0) {
            setRichiestaShow(richiesta[0]);
            document.getElementById("apri-show-richieste").click();
        }
    }

    return logged ?
        <div className="slide-in-top uk-container uk-container-large">
            <button style={{ display: "none" }} id="apri-show-richieste" uk-toggle="target: #show-richieste" type="button"></button>
            <img style={{ width: "150px" }} className="logo-pages" src={process.env.PUBLIC_URL + '/logo-pages-full.png'} alt="logo pages" />

            <div style={{ display: "inline" }} className="uk-margin-medium-bottom">
                <ModalInsertRichiesta loaderInsert={loaderInsert} durataVisita={durataVisita} setOpenModalInsert={setOpenModalInsert} openModalInsert={openModalInsert} addRichiesta={inserRichiesta} richiesteMedico={richieste} />
                <div style={{ display: "inline", float: "right" }} className="uk-text-right uk-margin-medium-bottom">
                    <button className="uk-button uk-button-danger" onClick={() => { localStorage.removeItem("token"); window.location.reload() }}>
                        <span style={{ lineHeight: "3em", verticalAlign: "top" }}>Esci</span>
                        <img style={{ marginLeft: "7px", width: "15px", fill: "white" }} src={process.env.PUBLIC_URL + '/logout.png'} />
                    </button>
                </div>
            </div>
            <PDFDocument richieste={richieste} />
            <div style={{ height: "110vh", width: "100%" }}>
                <Calendar
                    popup
                    selectable="ignoreEvents"
                    localizer={localizer}
                    events={events}
                    defaultView={Views.WEEK}
                    style={{ width: "100%" }}
                    views={['month', 'week', 'day']}
                    step={durataVisita}
                    messages={messages}
                    min={new Date(2008, 0, 1, 6, 0)}
                    max={new Date(2008, 0, 1, 18, 0)}
                    eventPropGetter={(eventStyleGetter)}
                    onSelectEvent={infoFocus}
                    onSelectSlot={setFocusEvent}
                />
            </div>
            <ModalViewRichiesta richiesta={richiestaShow} />
        </div>
        :
        <Redirect to="/login" />
}