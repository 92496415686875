import React from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

import Login from "./pages/login.js";
import Home from "./pages/home.js";

function App() {
  return (
    <div >
      <div style={{minHeight:"75vh"}}>
      <Router>
        <Route exact path="/login/" component={Login} />
        <Route exact path="/" component={Home} />
      </Router>
      </div>
      <div style={{ padding: "1% 12%", color: "white", backgroundColor: "#185B84", fontSize: "0.9em", marginTop: "50px", display: "flex" }}>
      <div style={{ width: "33%" }}>
          <img style={{ width: "100px",marginBottom:"15px" }} src={process.env.PUBLIC_URL + '/pages.webp'} alt="logo pages" /><br/>
          <a style={{ color: "white" }} href="https://www.pages.srl/privacy-policy-2/">Privacy Policy</a>
        </div>
        <div style={{ width: "33%", color: "white" }}>
          <a style={{ color: "white" }} href="https://www.instagram.com/pronto.dottore/?hl=it">Instagram</a><br />
          <a style={{ color: "white" }} href="https://www.facebook.com/ProntoDottore.it/">Facebook</a><br />
          <a style={{ color: "white" }} href="https://www.linkedin.com/company/prontodottore/">Linkedin</a>
        </div>
                <div style={{ width: "33%" }}>
          Via Bargagna, 2<br />
                    56124 Pisa<br />
                    P.IVA 02201690506<br />
                    Pec: pec@pec.pageservizi.it<br />
                    Email: info@pages.srl<br />
                    Registro imprese n°: 02201690506<br />
                    REA n°: PI-189112<br />
                    Cap. Soc.: €150.000<br />
        </div>

      </div>
    </div>
  );
}
export default App;