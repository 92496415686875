import React, { useEffect, useState, useRef } from "react";
import swal from 'sweetalert';
import { executeNetworkRequestWitouthAuth } from '../common/NetworkBusiness.js';
import { Redirect } from "react-router-dom";


export default () => {
    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [logged, setLogged] = useState(false);

    const login = async () => {
        const result = await executeNetworkRequestWitouthAuth("post", "login", { email: email, password: password });
        if (result.status === 200) {
            localStorage.setItem("token", result.data.token);
            localStorage.setItem("email", email);
            setLogged(true);
            return;
        }
        //Login errato per immissione sbagliata delle credenziali
        else if (result.status === 403) {
            swal({
                title: result.data.error,
                text: "Hai inserito username o password sbagliate!",
                icon: "error",
                button: "Torna al login"
            });
        }
    }
    const onKeyPress = (e) => {
        if (e.which === 13) {
            login();
        }
    }
    useEffect(() => {
        if (localStorage.getItem("token")) { setLogged(true); }
    }, [])

    return logged
        ? <Redirect to="/" />
        : (
            <div onKeyPress={onKeyPress} className="uk-container">
                <img className="logo-pages" src={process.env.PUBLIC_URL + '/logo-pages-full.png'} alt="logo pages" />
                <form onSubmit={(e) => { e.preventDefault(); login(); }}>
                    <label className="uk-form-label uk-text-bold " htmlFor="username-input">Username</label>
                    <input className="uk-input" onChange={(e) => { setEmail(e.target.value) }} type="text" id="username-input" placeholder="Es. mario" />
                    <label className="uk-form-label uk-text-bold" htmlFor="password-input">Password</label>
                    <input className="uk-input" onChange={(e) => { setPassword(e.target.value) }} type="password" id="password-input" placeholder="password" />
                    <button className="uk-button uk-button-primary uk-margin-top" onClick={login}>Login</button>
                </form>
            </div>
        )
}
