import React, { useState } from "react";
import moment from 'moment';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Modal from 'react-modal';
import it from 'date-fns/locale/it';

registerLocale('it', it)

export default (props) => {
    const [richiestaAdd, setRichiestaAdd] = useState({
        durata_richiesta: props.durataVisita,
        data_richiesta: new Date(),
        data_prenotazione: new Date(),
        nome: "",
        cognome: "",
        note: "",
        tipologia_richiesta: 2,
        stato: 0,
    });
    var subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }
    const filterTime = (time) => {
        const selectedTime = new Date(time);
        let check = true;
        let day = new Date(richiestaAdd.data_prenotazione).getDay()
        let date_with_time = new Date(richiestaAdd.data_prenotazione);
        date_with_time.setHours(new Date(time).getHours());
        date_with_time.setMinutes(new Date(time).getMinutes());
        let date2;
        let date1 = moment(date_with_time).format("YYYY-MM-DDTHH:mm");
        props.richiesteMedico.map((richiesta) => {
            date2 = moment(richiesta.data_prenotazione).format("YYYY-MM-DDTHH:mm");
            if ((richiesta.stato != 1) && moment(date1).isSame(date2)) {
                check = false;
            }
        });
        return check;
    }
    const customStyles = {
        content: {
            borderRadius: "25px",
            border: "0",
            width: "600px",
            height: "650px",
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)'
        }
    };
    function closeModal() {
        setIsOpen(false);
    }
    return (
        <div style={{ display: "inline" }}>
            <button className="uk-button uk-button-primary" onClick={() => props.setOpenModalInsert(true)}><span style={{ lineHeight: "3em", verticalAlign: "top" }}>Aggiungi Richiesta </span> <img className="color-white-svg" style={{ marginLeft: "5px", width: "25px", fill: "white" }} src={process.env.PUBLIC_URL + '/add.svg'} /></button>
            <Modal
                isOpen={props.openModalInsert}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Inserisci nuova prenotazione">
                <div className="">
                    <div >
                        <button style={{ border: "0", color: "grey", backgroundColor: "white", fontSize: "2em" }} onClick={() => props.setOpenModalInsert(false)} class="uk-modal-close-default" type="button" uk-close>X</button>
                    </div>
                    <h3><b>Aggiungi una nuova prenotazione</b></h3>
                    <div style={{ textAlign: "center", display: props.loaderInsert ? "block" : "none" }}>
                        <img style={{ width: "150px" }} src={process.env.PUBLIC_URL + '/loader.gif'} /><br />
                        <p style={{ marginTop: "0px" }}><b>Inserimento in corso</b></p>
                    </div>
                    <div style={{ display: props.loaderInsert ? "none" : "block" }}>
                        <div className="uk-grid-small" data-uk-grid>

                            <div className="uk-width-1-2@s">
                                <label className="uk-text-bold">Data Richiesta</label><br></br>
                                <DatePicker
                                    autoComplete="off"
                                    className="uk-input"
                                    selected={props.richiesta?.data_prenotazione}
                                    minDate={new Date()}
                                    name="data_prenotazione"
                                    selected={richiestaAdd.data_prenotazione}
                                    onChange={(date) => {
                                        setRichiestaAdd({ ...richiestaAdd, data_prenotazione: date })
                                    }}
                                    showMonthDropdown
                                    dateFormat={"P"}
                                    locale="it"
                                />
                            </div>
                            <div className="uk-width-1-2@s">
                                <label className="uk-text-bold">Orario richiesta</label><br></br>
                                <DatePicker
                                    autoComplete="off"
                                    onChange={(date) => {
                                        setRichiestaAdd({ ...richiestaAdd, ora_prenotazione: date })
                                    }}
                                    showTimeSelect
                                    selected={richiestaAdd.ora_prenotazione}
                                    className="uk-input"
                                    showTimeSelectOnly
                                    filterTime={filterTime}
                                    timeIntervals={props.durataVisita}
                                    locale="it"
                                    timeCaption="Orario"
                                    dateFormat="h:mm"
                                />
                            </div>
                        </div>
                        <p className="title-input">Nome</p>
                        <input value={richiestaAdd.nome} onChange={(e) => setRichiestaAdd({ ...richiestaAdd, nome: e.target.value })} type="text" className="uk-input" />

                        <p className="title-input">Cognome</p>
                        <input value={richiestaAdd.cognome} onChange={(e) => setRichiestaAdd({ ...richiestaAdd, cognome: e.target.value })} type="text" className="uk-input" />

                        <p className="title-input">Note</p>
                        <textarea row="3" type="text" className="uk-input" onChange={(e) => setRichiestaAdd({ ...richiestaAdd, note: e.target.value })} />

                        <p className="title-input" >Durata (minuti)</p>
                        <input type="number" value={richiestaAdd.durata_richiesta} onChange={(e) => setRichiestaAdd({ ...richiestaAdd, durata_richiesta: e.target.value })} className="uk-input" />
                        <br />
                        <br />
                        <button className="uk-button uk-button-primary" onClick={() => props.addRichiesta(richiestaAdd)}>Aggiungi prenotazione</button>

                    </div>
                </div>
            </Modal>
        </div >
    );
}