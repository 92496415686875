
import axios from 'axios';

/*
    Esegue una chiamata AJAX al backend senza passare il token.
    type: tipo di chiamata REST (POST,GET,DELETE,PUT)
    path: url di chiamata
    params: oggetto {}, con i valori da passare.
*/
export const executeNetworkRequestWitouthAuth = async (type, path, params) => {
    try {
        let data;
        switch (type) {
            case "get":
                data = await axios.get(`${process.env.REACT_APP_BACKEND}${path}`, params);
                break;
            case "put":
                data = await axios.put(`${process.env.REACT_APP_BACKEND}${path}`, params);
                break;
            case "delete":
                data = await axios.delete(`${process.env.REACT_APP_BACKEND}${path}`, params);
                break;
            case "post":
                data = await axios.post(`${process.env.REACT_APP_BACKEND}${path}`, params);
                break;
            default:
                data = await axios.get(`${process.env.REACT_APP_BACKEND}${path}`, params);
                break;
        }
        return data;
    }
    catch (error) {
        return error.response;
    }
}
/*
    Esegue una chiamata AJAX al backend passando il token.
    type: tipo di chiamata REST (POST,GET,DELETE,PUT)
    path: url di chiamata
    params: oggetto {}, con i valori da passare.
*/
export const executeNetworkRequestWithAuth = async (type, path, params) => {
    try {
        if (!localStorage.getItem("token")) {
            return false;
        }
        let data;
        switch (type) {
            case "get":
                data = await axios.get(`${process.env.REACT_APP_BACKEND}${path}`, {
                    headers: {
                        Authorization:
                            `Bearer ${localStorage.getItem("token")}`,
                    },
                    params
                });
                break;
            case "put":
                data = await axios.put(`${process.env.REACT_APP_BACKEND}${path}`, {
                    headers: {
                        Authorization:
                            `Bearer ${localStorage.getItem("token")}`,
                    },
                    params
                });
                break;
            case "delete":
                data = await axios.delete(`${process.env.REACT_APP_BACKEND}${path}`, {
                    headers: {
                        Authorization:
                            `Bearer ${localStorage.getItem("token")}`,
                    },
                    params
                });
                break;
            case "post":
                data = await axios.post(`${process.env.REACT_APP_BACKEND}${path}`, params, {
                    headers: {
                        'Authorization': `Bearer ${localStorage.getItem("token")}`,
                    },
                });
                break;
            default:
                data = await axios.get(`${process.env.REACT_APP_BACKEND}${path}`, {
                    headers: {
                        Authorization:
                            `Bearer ${localStorage.getItem("token")}`,
                    },
                    params
                });
                break;
        }
        return data;
    }
    catch (error) {
        return error;
    }
}