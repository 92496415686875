import React, { useState } from "react";
import moment from 'moment';
require('moment/locale/it.js');
export default (props) => {
    return (
        <div id="show-richieste" data-uk-modal>
            <div className="uk-modal-dialog uk-modal-body modal-view-richiesta">
                <h3>Richiesta con identifcativo : {props.richiesta?.id}</h3>
                {props.richiesta?.nome && <b>Nome cognome</b>}
                <p>{props.richiesta?.nome} {props.richiesta?.cognome}</p>
                <b>Data prenotazione</b>
                <p>{moment(new Date(props.richiesta?.data_prenotazione)).local().format("YYYY-MM-DD HH:mm")}</p>
                {props.richiesta?.note && <div>
                    <b>Note</b>
                    <p style={{whiteSpace:"pre-wrap",lineHeight:"1.4em"}}>{props.richiesta?.note}</p>
                </div>}
                <b>Tipologia richiesta</b>
                <p>{props.richiesta?.tipologia_richiesta == 0 ? "Prenotazione" : (props.richiesta?.tipologia_richiesta == 1 ? "Ricetta" : "Inserita dal calendario")}</p>
                {props.richiesta?.tipologia_richiesta == 0 &&
                    <div>
                        <b>Indirizzo prenotazione</b>
                        <p>{props.richiesta?.indirizzo_prenotazione}</p>
                    </div>
                }
                <button className="uk-button uk-button-danger uk-modal-close">Chiudi</button>
            </div>
        </div >
    )
}