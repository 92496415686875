import React, { useState } from "react";
import { jsPDF } from "jspdf";
import moment from 'moment';
require('moment/locale/it.js');
Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

export default (props) => {
    const ref = React.createRef();
    const [displayPDF, setDisplayPDF] = useState(false);
    const [limit, setLimit] = useState(0);
    function download(limit) {
        setLimit(limit);
        setTimeout(() => {
            setDisplayPDF(true);
            setTimeout(() => {
                let pdf = new jsPDF("p", "pt", "a4");
                pdf.html(document.getElementById('toPdf'), {
                    callback: function () {
                        pdf.save(`pronto-dottore-richieste-${moment(new Date()).format("DD-MM-YYYY__hh-mm")}`);
                        setDisplayPDF(false);
                    }
                });
            }, 1200);
        }, 300);
    }

    return <div style={{ marginTop: "25px", marginBottom: "25px" }}>
        <span style={{ marginRight: "15px" }}> <b>Scarica Report PDF</b> </span>
        <button className="uk-button uk-button-default uk-button-small" onClick={() => download(1)}><span style={{ lineHeight: "3em", verticalAlign: "top" }}>Oggi</span> <img className="pdf-align-center" style={{ marginLeft: "5px", width: "15px" }} src={process.env.PUBLIC_URL + '/downloadpdf.png'} /></button>
        <button className="uk-button uk-button-default uk-button-small" onClick={() => download(0)}><span style={{ lineHeight: "3em", verticalAlign: "top" }}>Settimana corrente</span> <img className="pdf-align-center" style={{ marginLeft: "5px", width: "15px" }} src={process.env.PUBLIC_URL + '/downloadpdf.png'} /></button>
        <div id="loader" style={{ display: displayPDF ? "block" : "none" }}>
            <span>
                <img src={process.env.PUBLIC_URL + '/loader.gif'} /><br />
            </span>
        </div>
        <div id="toPdf" style={{ display: displayPDF ? "block" : "none", width: '15.8cm', minHeight: '29,7cm' }}>
            <img style={{ width: "120px" }} className="logo-pages" src={process.env.PUBLIC_URL + '/logo-pages-full.png'} alt="logo pages" />
            <p style={{ marginTop: "0px", textAlign: "center" }}>
                Report richieste al giorno                     <b>
                    {moment(new Date().toISOString()).format("DD/MM/YYYY")}
                </b>
                <br />
                <span>
                    Numero richieste <b>{props.richieste.filter((richiesta) => (limit == 0 ? (richiesta.data_prenotazione > new Date().toISOString() && richiesta.data_prenotazione < new Date().addDays(7).toISOString()) : new Date(richiesta.data_prenotazione).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0)) ? true : false).length}</b>
                </span>
            </p>
            <div style={{ paddingLeft: "15px", paddingRight: "15px" }}>
                <div className="richieste-pdf uk-grid-medium uk-child-width-1-3@m" style={{ fontSize: "0.6em" }} data-uk-grid>
                    {
                        props.richieste.filter((richiesta) => limit == 0 ? (richiesta.data_prenotazione > new Date().toISOString() && richiesta.data_prenotazione < new Date().addDays(7).toISOString()) : (new Date(richiesta.data_prenotazione).setHours(0, 0, 0, 0) == new Date().setHours(0, 0, 0, 0)))
                            .map((richiesta, index) =>
                                <div style={{ marginTop: (index > 8 && (index % 9 == 0 || index % 9 == 1 || index % 9 == 2)) ? (index > 14 ? "250px" : "100px") : "10px" }} key={richiesta.id}>
                                    <div style={{ height: "160px", padding: "15px", border: "1px solid lightgray", borderRadius: "10px" }}>
                                        <h6 style={{ marginBottom: "5px" }}>Identificativo:<b>{richiesta.id}</b></h6>
                                        {richiesta.nome && <span>Nome e cognome:<br /> <b> {richiesta.nome} {richiesta.cognome}</b><br /></span>}
                                        {richiesta.note && <span>Note:<br /> <b>{richiesta.note}</b><br /></span>}
                                        {richiesta.indirizzo_prenotazione && <span>Indirizzo: <br /><b>{richiesta.indirizzo_prenotazione}</b><br /></span>}
                                    Prenotato da : <br /><b>{richiesta.tipologia_richiesta == 2 ? "Inserita da calendario" : "Inserita da operatore"}</b>
                                    Data prenotazione: <br /><b>{moment(new Date(richiesta.data_prenotazione)).format("DD/MM/YYYY-HH:mm")}</b><br />
                                    </div>
                                </div>
                            )
                    }
                </div>

            </div>
        </div>

    </div>

}